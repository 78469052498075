import React from 'react'
import { graphql } from 'gatsby'
import Seo from '@seo'
import PageHeader from '@pageHeader'
import WorksGrid from '@works/WorksGrid'
import Footer from '@footer'
import { useWorks } from '@hooks/useWorks'
import { PageProps } from 'types'

const Works = ({ data: { contentfulPage } }: PageProps): JSX.Element => {
  const { pageTitle, pageColor, pageDescription, titleFirst, titleSecond, excerptFirst, excerptSecond } = contentfulPage
  const works = useWorks()

  return (
    <>
      <Seo title={pageTitle} themeColor={pageColor} description={pageDescription} />
      <PageHeader
        title={{
          first: titleFirst,
          second: titleSecond,
        }}
        excerpt={{
          first: excerptFirst,
          second: excerptSecond,
        }}
        color={pageColor}
      />
      <div className="column is-12 page works-page">
        <WorksGrid items={works} />
      </div>
      <Footer />
    </>
  )
}

export default Works

export const query = graphql`
  query WorksQuery($worksPageId: String!) {
    contentfulPage(contentful_id: { eq: $worksPageId }) {
      id
      pageTitle
      pageColor
      pageDescription
      titleFirst
      titleSecond
      excerptFirst
      excerptSecond
    }
  }
`
